import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const CheckIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path
        d='M8.726,0a8.726,8.726,0,1,0,8.726,8.726A8.76,8.76,0,0,0,8.726,0ZM7.67,12.695,3.849,8.874,5.295,7.428,7.737,9.87l4.9-4.457,1.376,1.513Z'
        fill='#fff'
      />
    </SvgIcon>
  )
}
