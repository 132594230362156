import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import styled from '@mui/material/styles/styled'
import { tooltipClasses } from '@mui/material/Tooltip'
import { TypographyProps } from '@mui/material/Typography'

import { Trans } from 'react-i18next'

interface TypographyTooltipTypes extends TypographyProps {
  values: { [key: string]: string }
  keyTypography: string
  t: any
  keysTooltip?: { [key: string]: string }
  Icon?: any
  disabled?: boolean
}

export const TooltipStyled = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(20,20,20,0.98)',
    boxShadow: theme.shadows[1],
  },
  [`& .${tooltipClasses.arrow}`]: {
    boxShadow: theme.shadows[1],
    color: 'rgba(20,20,20,0.98)',
  },
}))

export const TypographyWithTooltips = ({
  values,
  keyTypography,
  t,
  keysTooltip,
  Icon,
  disabled = false,
  ...props
}: TypographyTooltipTypes) => {
  const Explainer = ({ children, tooltip }) => (
    <TooltipStyled
      disabled={disabled}
      arrow
      title={<Typography variant='body2'>{String(t(tooltip))}</Typography>}
      placement='top'
      enterTouchDelay={0}
    >
      <Link
        {...(disabled && {
          style: { color: '#8f8d8d', textDecoration: 'line-through' },
        })}
        color='textPrimary'
      >
        {children}
      </Link>
    </TooltipStyled>
  )
  const componentsObject = {}
  keysTooltip &&
    Object.entries(keysTooltip).forEach(([key, value]) => {
      // @ts-ignore
      componentsObject[key] = <Explainer tooltip={value} />
    })

  return (
    <Typography {...props} gutterBottom>
      {Icon && Icon}
      <Trans
        values={values}
        i18nKey={keyTypography}
        t={t}
        components={componentsObject}
      />
    </Typography>
  )
}
