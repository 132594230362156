import { PlanData_SS } from '@fivano/models'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowRight from '@mui/icons-material/ArrowRight'
import { Theme } from '@mui/material/styles/createTheme'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import capitalize from '@mui/material/utils/capitalize'
import Link from '@mui/material/Link'
import { CheckIcon } from 'icons/CheckIcon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProducerKitsButton } from './ProducerKitsButton'
import { TypographyWithTooltips } from './TypographyWithTooltip'

export type PlanTypes = 'basic' | 'premium' | 'pro'

export const PricingCards = ({
  plans,
  period,
  onSelect,
  onSelectCurrent,
  currentSubscription,
  currentSubscriptionPeriod,
}: {
  plans: { [key: string]: PlanData_SS }
  period: 'month' | 'year'
  onSelect: (plan: PlanTypes, period: 'month' | 'year') => void
  onSelectCurrent?: (value: PlanTypes) => void
  currentSubscription?: PlanTypes
  currentSubscriptionPeriod?: 'month' | 'year'
}) => {
  const [currency] = useState<string>('USD')
  const [expandCard, setExpandCard] = useState('')
  const { t } = useTranslation('plans')
  const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const planOptions: PlanTypes[] = ['basic', 'premium', 'pro']

  return (
    <Grid container justifyContent={'center'} spacing={2}>
      {planOptions.map(plan => {
        const priceText = `${
          currency === 'EUR' ? '€' : currency === 'USD' ? '$' : 'D'
        }
    ${
      period === 'month'
        ? parseFloat(
            plans[plan]?.periodPrices[period][currency],
          ).toLocaleString('en-US', {
            minimumFractionDigits: 2,
          })
        : parseFloat(
            plans[plan]?.periodPrices[period][currency],
          ).toLocaleString('en-US', {
            minimumFractionDigits: 2,
          })
    } ${downSM ? '' : 'Per ' + capitalize(period)}`
        return (
          <Grid
            item
            container
            justifyContent={'center'}
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={plan}
          >
            <Box
              sx={{
                position: 'relative',
                border: '1px solid #fff',
                borderRadius: '10px',
                maxWidth: downSM ? '100%' : '330px',
                width: '100%',
              }}
            >
              {!downSM && (
                <Box
                  sx={{
                    padding: '16px 0px',
                    backgroundImage:
                      'linear-gradient(90deg, rgba(252,92,102,1) 0%, rgba(247,133,85,1) 100%)',
                    height: 64,
                    borderTopLeftRadius: '9px',
                    borderTopRightRadius: '9px',
                    borderBottom: '1px solid #fff',
                  }}
                >
                  <Typography variant='h5' align='center'>
                    {capitalize(plan)} {downSM && `- ${priceText}`}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  padding: '22px 22px 30px 22px',
                  position: 'relative',
                }}
              >
                {plan === currentSubscription &&
                  currentSubscriptionPeriod === period && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 5,
                        backgroundImage:
                          'linear-gradient(90deg, rgba(183, 183, 183, 0.4) 0%, rgba(107, 107, 107, 0.4) 100%)',
                      }}
                    />
                  )}
                {!downSM && (
                  <>
                    <Typography variant='h1' fontWeight={700} align='center'>
                      {plans[plan]?.credits * (period === 'year' ? 12 : 1) || 0}
                    </Typography>

                    <Typography variant='h6' align='center' fontWeight={500}>
                      {period === 'year' ? 'Yearly' : 'Monthly'} Download
                      Credits
                    </Typography>
                    <Box
                      sx={{
                        borderTop: '1px solid #fff',
                        margin: '8px 36px 8px 36px',
                      }}
                    />
                    <Typography
                      align='center'
                      gutterBottom
                      variant='h6'
                      fontWeight={500}
                      style={{
                        marginBottom: '24px',
                      }}
                    >
                      {priceText}
                    </Typography>
                  </>
                )}

                {downSM && (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant='h5' align='left'>
                        {capitalize(plan)} {`- ${priceText}`}
                      </Typography>
                      <Box
                        sx={{
                          borderTop: '1px solid #fff',
                          margin: '8px 90% 8px 0px',
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography fontWeight={500}>
                        {plans[plan]?.credits * (period === 'year' ? 12 : 1) ||
                          0}{' '}
                        Download Credits Per
                        {period === 'year' ? ' Year' : ' Month'}
                      </Typography>
                    </Grid>
                    <Grid item marginLeft='-8px'>
                      {expandCard === plan ? <ArrowDropDown /> : <ArrowRight />}
                    </Grid>
                    <Grid item>
                      <Link
                        color='textPrimary'
                        onClick={() =>
                          setExpandCard(expandCard === plan ? '' : plan)
                        }
                      >
                        {expandCard === plan ? 'Hide Details' : 'See Details'}
                      </Link>
                    </Grid>
                  </Grid>
                )}

                <div
                  style={{
                    overflow: 'hidden',
                    height: downSM
                      ? expandCard === plan
                        ? 'auto'
                        : '0px'
                      : '110px',
                  }}
                >
                  {
                    <>
                      <TypographyWithTooltips
                        variant='body1'
                        t={t}
                        values={{}}
                        keyTypography='description_credit_is_download'
                        Icon={
                          <CheckIcon
                            sx={{
                              width: '24px',
                              height: '22px',
                              margin: '2px 8px -6px 0px',
                              paddingTop: '4px',
                            }}
                          />
                        }
                      />
                      <TypographyWithTooltips
                        variant='body1'
                        t={t}
                        values={{}}
                        keyTypography='description_download_seperate'
                        keysTooltip={{
                          tooltip_separate_audio_files:
                            'tooltip_separate_audio_files',
                        }}
                        Icon={
                          <CheckIcon
                            sx={{
                              width: '24px',
                              height: '22px',
                              margin: '2px 8px -6px 0px',
                              paddingTop: '4px',
                            }}
                          />
                        }
                      />
                      <TypographyWithTooltips
                        variant='body1'
                        t={t}
                        values={{}}
                        keyTypography='description_royalty_free'
                        keysTooltip={{ tooltip: 'tooltip_royalty_free' }}
                        Icon={
                          <CheckIcon
                            sx={{
                              width: '24px',
                              height: '22px',
                              margin: '2px 8px -6px 0px',
                              paddingTop: '4px',
                            }}
                          />
                        }
                      />
                    </>
                  }
                </div>
                <Grid
                  container
                  justifyContent='center'
                  marginTop={downSM ? '16px' : '36px'}
                >
                  <Grid item width={downSM ? '100%' : '160px'}>
                    <ProducerKitsButton
                      size='small'
                      sx={{ zIndex: 6 }}
                      fullWidth
                      {...(plan === currentSubscription &&
                        currentSubscriptionPeriod === period && {
                          gradient:
                            'linear-gradient(90deg, rgb(140 140 140) 0%, rgb(107 107 107) 100%)',
                        })}
                      onClick={() => {
                        if (
                          plan === currentSubscription &&
                          currentSubscriptionPeriod === period
                        ) {
                          onSelectCurrent && onSelectCurrent(plan)
                        } else {
                          onSelect(plan, period)
                        }
                      }}
                    >
                      {buttonLabel(
                        plan,
                        period,
                        currentSubscription,
                        currentSubscriptionPeriod,
                      )}
                    </ProducerKitsButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        )
      })}
    </Grid>
  )
}
const buttonLabel = (
  plan: PlanTypes,
  period: 'month' | 'year',
  currentSubscription?: PlanTypes,
  currentSubscriptionPeriod?: 'month' | 'year',
) => {
  if (currentSubscription === undefined) {
    /** if currentSubscription is undefined then it is the pricing table */
    return 'Get Started'
  } else {
    if (plan === currentSubscription && currentSubscriptionPeriod === period) {
      return 'Currently'
    } else {
      return 'Choose'
    }
  }
}
